export default function accessibleMenu() {
  // Menu principal
  var navItems = document.querySelectorAll('.main-menu li ');
  var navItemsArr = Array.from(navItems);
  var subMenuItemsArr = Array.from(document.querySelectorAll('.sub-menu li a '));

  // Ajoute un tabindex=-1 aux <a> des sous-menu pour empecher la navigation au clavier
  subMenuItemsArr.forEach(function(subMenuItem) {
    subMenuItem.setAttribute('tabindex', -1);
    subMenuItem.parentElement.classList.add('sub-menu-item');
  });

  navItems.forEach(function(navItem) {
    /* Ajoute aria-expanded='false' aux liens ayant un sous-menu */
    if (navItem.classList.contains('menu-item-has-children')) {
      navItem.children[0].setAttribute('aria-haspopup', true);
      navItem.children[0].setAttribute('aria-expanded', false);
      navItem.children[0].setAttribute('role', 'button');
    }

    /* Fonction d'ouverture du sous-menu du menu principal */
    function OpenSubMenu() {
      var ExpandedList = Array.from(document.querySelectorAll('.submenu-expanded'));
      var subMenuOpenItems = Array.from(document.querySelectorAll('.submenu-expanded li a'));

      if (!navItem.children[1].classList.contains('submenu-expanded')) {
        ExpandedList.forEach(function(Expanded) {
          if (Expanded.classList.contains('submenu-expanded')) {
            Expanded.classList.remove('submenu-expanded');
            Expanded.previousElementSibling.setAttribute('aria-expanded', false);
            //                Expanded.children[0].children[1].setAttribute('tabindex', -1);
            //
            // Remet tabindex=-1 aux items <a> du sous-menu
            subMenuOpenItems.forEach(function(subMenuOpenItem) {
              subMenuOpenItem.setAttribute('tabindex', -1);
            });
          }
        });
      }

      if (navItem.children[1].classList.contains('submenu-expanded')) {
        navItem.children[1].classList.remove('submenu-expanded');
        // Remet tabindex=-1 aux items <a> du sous-menu
        subMenuOpenItems.forEach(function(subMenuOpenItem) {
          subMenuOpenItem.setAttribute('tabindex', -1);
        });
      } else {
        navItem.children[1].classList.add('submenu-expanded');

        // Modifie le tabindex des <a> pour les rendre navigable au clavier
        var thisSubMenuOpenItems = Array.from(document.querySelectorAll('.submenu-expanded li a'));

        thisSubMenuOpenItems.forEach(function(subMenuOpenItem) {
          subMenuOpenItem.setAttribute('tabindex', 0);
        });
      }

      var ariaX = navItem.children[0].getAttribute('aria-expanded');
      if (ariaX === 'false') {
        navItem.children[0].setAttribute('aria-expanded', true);
      } else {
        navItem.children[0].setAttribute('aria-expanded', false);
      }
    }

    /* Ouverture du sous-menu au click */
    navItem.addEventListener('click', OpenSubMenu);

    /* Ouverture su sous-menu au keyup du bouton Entrée ou barre d'espace */
    navItem.addEventListener('keypress', function(e) {
      if (e.keyCode === 32) {
        e.preventDefault();
        OpenSubMenu();
      }
    });
  });
}
