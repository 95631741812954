export default function mobileNav() {
  /*****************  Navigation du site pour les mobiles *****************/

  var navTrigger = document.querySelector('.nav-trigger');
  var body = document.querySelector('body');
  var mainNav = document.querySelector('.main-navigation');

  navTrigger.addEventListener('click', function(e) {
    e.preventDefault();
    body.classList.toggle('nav-open');
    mainNav.classList.toggle('menu-open');
  });

  // $('.nav-trigger').on('click', function(event) {
  //   event.preventDefault();
  //   $('body').toggleClass('nav-open');
  //   $('.main-navigation').toggleClass('menu-open');
  // });

  /************* Closes the Responsive Menu on Menu Item Click *****************/

  // $('.main-navigation a').click(function() {
  //   if ($('.main-navigation').hasClass('menu-open')) {
  //     $('.nav-trigger').click();
  //   }
  // });
}
